import { useState } from 'react';
import { useAuth } from '@contexts/auth/AuthProvider';

import { Permission } from '../../../types';

const usePermission = (permission: Permission) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState<boolean>();

  const { isAllowedTo } = useAuth();

  isAllowedTo(permission).then((allowed) => {
    setLoading(false);
    setAllowed(allowed);
  });
  return [loading, allowed];
};

export default usePermission;
