import { FC } from 'react';
import { Alert } from 'react-bootstrap';

const AccessDenied: FC = () => (
  <div className="container">
    <div className="row">
      <div className="col-12 text-center mt-5">
        <Alert variant="danger" className="label m-0 text-danger">
          <i className="eha-level4 text-danger me-2" />
          You do not have permission to view the requested page
        </Alert>
        {/* <p/>
            <Link className='mt-5' href="#" passHref>
                <button type="button" className="btn btn-cancel" onClick={() => history.go(-1)}>Go back</button>
            </Link> */}
      </div>
    </div>
  </div>
);

export default AccessDenied;
