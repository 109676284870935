import React from 'react';
import useHasGroup from './hooks/useHasGroup';
import { getFeatureByName } from '@settings/utils/featureSettings';
import {
  WITH_DOCTOR_STAGE,
  READY_FOR_DOCTOR,
  TRANSITION_CONTROL_ENCOUNTER,
} from '@constants/constants';

type Props = {
  group: string;
  isEmergency: boolean;
  ticketStage: string;
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
};

// This component is meant to be used to restrict nurse assessment inside an encounter for transition control
const RestrictNurseAssessment: React.FunctionComponent<Props> = ({
  group,
  ticketStage,
  isEmergency,
  fallback,
  loadingComponent,
  children,
}) => {
  const [loading, hasGroup] = useHasGroup(group);
  const feature = getFeatureByName(TRANSITION_CONTROL_ENCOUNTER);

  if (loading) {
    return <>{loadingComponent}</>;
  }

  if (!feature?.isEnabled) {
    return <>{children}</>;
  }
  if (
    !hasGroup ||
    !ticketStage ||
    isEmergency ||
    (hasGroup &&
      (ticketStage.toLowerCase().includes(READY_FOR_DOCTOR) ||
        ticketStage.toLowerCase() === WITH_DOCTOR_STAGE))
  ) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default RestrictNurseAssessment;
