import { useRouter } from 'next/router';
import Image from 'next/image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Link from 'next/link';
import Head from 'next/head';
import { getSession, useSession, signOut } from 'next-auth/react';

import { NextApplicationPage } from '@pages/_app';

import { ISession } from '@interfaces/session';

import { permissions } from '@contexts/auth';
import usePermission from '@contexts/auth/hooks/usePermission';

// import { saveOriginPathToStorage } from '@utils/utils';

const Home: NextApplicationPage = () => {
  const router = useRouter();

  const { data: session } = useSession() as unknown as ISession;

  const [, isQualityApp] = usePermission(permissions.EHA_QUALITY_APP);

  const renderQACard = () => {
    if (isQualityApp) {
      return (
        <>
          <Link href="/quality-app" passHref>
            <a>
              <div className="ec-landing-card">
                <div className="ec-card-label">Review, Audit & Report</div>
                <div className="ec-card-svgs">
                  <div className="ec-card-main-logo-container">
                    <div className="ec-report-graph">
                      <Image
                        src={require('/public/img/EHACare-BarIcon.svg')}
                        width={160}
                        height={90}
                        alt=""
                      />
                    </div>
                    <div className="ec-report-person">
                      <Image
                        src={require('/public/img/EHACare-QappPerson.svg')}
                        width={160}
                        height={90}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="ec-card-footer-logo-container">
                    <Image
                      src={require('/public/img/EHAQA-green.svg')}
                      height={28}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </>
      );
    } else {
      null;
    }
  };

  return (
    <>
      {' '}
      <Head>
        <title>Home</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="shortcut icon" href="/img/favicon-32x32.png" />
        {/* <link rel="stylesheet" type="text/css" href="/styles.css" media="screen"/> */}
      </Head>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        fixed="top"
        className="eha-topnav">
        <Image
          src={require('/public/img/EHAQA-main.svg')}
          width={70}
          height={45}
          className="d-inline-block mt-n2 mt-lg-n1 "
          alt="Quality App"
          test-id="ehacare-logo"
        />

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-3" />
        <Navbar.Collapse id="basic-navbar-nav">
          <>
            <Nav activeKey={router.pathname} className="ms-auto">
              <NavDropdown
                title={session.user.name ?? ''}
                id="basic-nav-dropdown"
                className="qapp-user-name qapp-nav-profile"
                test-id="user-name"
                align="end"
                renderMenuOnMount>
                <Link href="/user/profile" passHref>
                  <NavDropdown.Item test-id="profile-nav">
                    Profile
                  </NavDropdown.Item>
                </Link>
                <Link
                  href="https://form.asana.com/?k=fGZgQc-9MSAR1A3SxeYJpQ&d=657186903698473"
                  passHref>
                  <NavDropdown.Item target="_blank" test-id="send-request-nav">
                    Report Issue
                  </NavDropdown.Item>
                </Link>
                <NavDropdown.Divider />
                <Link href="#" passHref>
                  <NavDropdown.Item
                    onClick={async () => await signOut()}
                    test-id="logout-nav">
                    Log out
                  </NavDropdown.Item>
                </Link>
                <NavDropdown.Divider />
              </NavDropdown>
            </Nav>
          </>
        </Navbar.Collapse>
      </Navbar>
      {/* Page Body Section  */}
      <main className="ec-landing-main">
        <div className="ec-card-container">
          <Link href="/ambulatory-app" passHref>
            <a>
              <div className="ec-landing-card">
                <div className="ec-card-label">Ambulatory App </div>
                <div className="ec-card-svgs">
                  <div className="ec-card-main-logo-container">
                    <Image
                      src={require('/public/img/EHACare.svg')}
                      width={180}
                      height={120}
                      alt=""
                    />
                  </div>
                  <div className="ec-card-footer-logo-container">
                    <Image
                      src={require('/public/img/EHACare-logo-black.svg')}
                      height={28}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </Link>

          <Link href={process.env.NEXT_PUBLIC_BOOKING_URL as string}>
            <a rel="noopener noreferrer">
              <div className="ec-landing-card">
                <div className="ec-card-label">Booking Management</div>
                <div className="ec-card-svgs">
                  <div className="ec-card-main-logo-container">
                    <Image
                      src={require('/public/img/EHA-Booking-Logo.svg')}
                      width={190}
                      height={140}
                      alt=""
                    />
                  </div>
                  <div className="ec-card-footer-logo-container">
                    <Image
                      src={require('/public/img/EHAQA-booking.svg')}
                      height={28}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </Link>

          {/* Qapp card */}
          {renderQACard()}

          <Link href="/settings/ambulatory-care/user-admin/users">
            <a>
              <div className="ec-landing-card">
                <div className="ec-card-label">Configuration & Settings</div>
                <div className="ec-card-svgs">
                  <div className="ec-card-main-logo-container">
                    <div className="ec-config-nuticon">
                      <Image
                        src={require('/public/img/EHACare-config-nut-icon.svg')}
                        width={150}
                        height={85}
                        alt=""
                      />
                    </div>
                    <div className="ec-config-person">
                      <Image
                        src={require('/public/img/EHACare-ConfigPerson.svg')}
                        width={175}
                        height={100}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="ec-card-footer-logo-container">
                    <div className="ec-config-footer">
                      <Image
                        src={require('/public/img/EHACare-logo-black.svg')}
                        height={28}
                        alt=""
                      />
                      <div className="ec-config-text">Configuration</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </Link>

          <div
            onClick={() => {
              // saveOriginPathToStorage('/');
              // router.push('/chat-app');
            }}>
            <div className="ec-landing-card">
              <div className="ec-card-label">Messaging Center</div>
              <div className="ec-card-svgs">
                <div className="ec-card-main-logo-container">
                  <Image
                    src={require('/public/img/messaging-center.svg')}
                    width={180}
                    height={120}
                    alt=""
                  />
                </div>
                <div className="ec-card-footer-logo-container">
                  <div className="ec-config-footer">
                    <Image
                      src={require('/public/img/EHACare-logo-black.svg')}
                      height={28}
                      alt=""
                    />
                    <div className="ec-messaging-text">Messaging center</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

Home.requireAuth = true;

export async function getServerSideProps(context: any) {
  return {
    props: {
      session: await getSession(context),
    },
  };
}

export default Home;
