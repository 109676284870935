import { useState } from 'react';
import { useAuth } from '@contexts/auth/AuthProvider';

const useHasGroup = (group: string) => {
  const [loading, setLoading] = useState(true);
  const [hasGroup, setHasGroup] = useState(false);

  const { checkHasGroup } = useAuth();

  checkHasGroup(group).then((hasGroup) => {
    setLoading(false);
    setHasGroup(hasGroup);
  });

  return [loading, hasGroup];
};

export default useHasGroup;
